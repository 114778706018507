
import axios from "axios";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			baseURL:
				process.env.NUXT_ENV_API_BASE_URL ||
				"https://localhost:7283/api",
			isLoading: true,
		};
	},
	computed: {
		themes() {
			return this.site?.themes;
		},
		site() {
			return this.$store.state.site;
		},
	},
	async mounted() {
		await this.fetchExpiredContent();
		await this.pushEncryptedTrackingPixel();
		this.isLoading = false;
	},
	methods: {
		...mapActions("library", ["fetchExpiredContent"]),
		async pushEncryptedTrackingPixel() {
			const marketerNumber = this.site.user.ddcUserData?.marketerNo;
			if (marketerNumber) {
				try {
					const response = await axios.get(
						`${this.baseURL}/tooling/PixelGateway?marketerNumber=${marketerNumber}`
					);
					if (response.data) {
						this.$gtm.push({
							event: "tracking-pixel-loaded",
							marketer_number:
								response.data.encryptedMarketerNumber,
						});
					}
				} catch (e) {
					return "";
				}
			}
		},
	},
};
